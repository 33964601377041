@import url(https://fonts.googleapis.com/css2?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Welcome to the Cult */

/* Global */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
a{
    text-decoration: none;
    color: #fff;
}
.logo {
    font-size: 5vmin;
}
a:visited {
    color: #fff;
}
a:hover {
    color: #e22b69;
}
button{
    text-decoration: none;
}
button:visited {
    color: #fff;
}
button:hover {
    color: #e22b69;
}
ul {
    list-style: none;
}
body , html {
    overflow-x: visible !important;
    overflow-x: initial !important;
    min-height: 100%;
}
.active {
    font-weight: bold;
}
.navbar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px;
    color: whitesmoke;
}
.nav-links {
    display: flex;
    align-items: center;
}
.nav-links li {
    margin: 0 30px;
}
.nav-links li .beta {
    color: #e22b69;
    position: absolute;
    display: inline;
    padding-left: 5px;
}

header {
    width: 100vw;
    height: 100vh;
    background-image: url(/static/media/bg-img.8460d4ea.png);
    background-position: bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-content {
    margin-bottom: 150px;
    color: whitesmoke;
    text-align: center;
    width: 100%;
    padding: 20% auto;
    color: whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.header-content h1 {
    font-size: 52px;
    color: whitesmoke;
    margin-bottom: 10px;
}
.header-content p {
    font-size: 24px;
    color: whitesmoke;
    margin-bottom: 15px;
}
/* CONNECT BUTTON */
.connect-btn {
    margin-top: 30px;
    padding: 8px 30px;
    background: white;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-weight: bold;
    box-shadow: inset 0px 0px 2px 2px #e4e4e4;
}
/* MINT BUTTON */
.mint-btn {
    padding: 8px 30px;
    background: linear-gradient(to right, #e22b69, #f2ec64);
    border-radius: 30px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
}

/*Dashboard Button*/
.btn {
    margin-top: 30px;
    padding: 8px 30px;
    background: linear-gradient(to right, #e22b69, #f2ec64);
    border-radius: 30px;
    color: #fff;
    background: linear-gradient(to right, #e22b69, #f2ec64);
    cursor: pointer;
}
/* PROGRESS BAR */
.progress-bar {
    width: 300px;
    height: 40px;
    border: 3px solid #fff;
    text-align: center;
    float: left;
    margin-bottom: 15px;
}
.progress-bar-fill {
    height: 100%;
    width: 52%;
    background: white;
}
section {
    width: 80%;
    margin: 40px auto;
    margin-bottom: 25px;
}
.title {
    text-align: center;
    font-size: 24px;
    color: #000;
    margin-bottom: 15px;
}
.row {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: space-between;
}
.row .col {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.row .col img {
    width: 80%;
    border-radius: 15px;
}
.drops .row {
    margin-top: 50px;
    margin-bottom: 50px;
}
.drops h1 {
    color: #e22b69;
}
h4 {
    font-size: 24px;
    margin: 20px auto;
}
p {
    font-size: 16px;
    color: grey;
    padding: 0px 40px;
}

/* WALLET */
.wallet h1 {
    color: #e22b69;
}
.wallet h4 {
    color: #e22b69;
    font-size: 18px;
}
.wallet {
    width: 100%;
    background: #000000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 0px;
    margin-top: 0px;
    align-items: center;
    min-height: 300px;
    padding: 25px;
}
.wallet .row {
    align-items: center;
    justify-content: space-between;
    display: flex;
    background-color: rgb(14, 14, 14);
    padding: 45px;
    border-radius: 30px;
    box-shadow: inset 0px 0px 15px 15px #000000;
}
.wallet img {
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    box-shadow: 0px 2px 5px rgb(0, 0, 0);
    max-height: 400;
    max-width: 400;
}
.wallet img:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 2.1px 5.1px rgb(0, 0, 0);
}

/* ROADMAP */
.roadmap h1 {
    color: whitesmoke;
}
.roadmap h4 {
    color: whitesmoke;
    font-size: 16px;
}
.roadmap {
    width: 100%;
    height: 50vh;
    background: #000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 0px;
    background-image: url(/static/media/bg-img-3.9906b744.png);
    justify-self: center;
}
.roadmap-content {
    width: 100%;
    padding: 10%;
    min-height: 300px;
    color: whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.roadmap-content .row {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: space-between;
}
.roadmap-content h1 {
    font-size: 38px;
    margin: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}
.roadmap-content p {
    color: white;
}
.roadmap-content .btn {
    background: linear-gradient(to right, #e22b69, #f2ec64);

}
.roadmap-content .btn:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 2.1px 5.1px rgb(0, 0, 0);
}
.roadmap-content a {
    color: white;
}

/* DASHBOARD */
.dashboard .btn {
    margin-top: 30px;
}
.dashboard-content .btn {
    background: linear-gradient(to right, #e22b69, #f2ec64);
}
.dashboard-content .btn:hover {
    background: linear-gradient(to right, #e22b69, #f2ec64);
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 2.1px 5.1px rgb(0, 0, 0);
}
.dashboard-content a {
    color: white;
}
.dashboard {
    width: 100%;
    height: 400px;
    background: #000;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    text-align: center;
    align-items: center;
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.dashboard-content {
    width: 100%;
    padding: 20% auto;
    color: whitesmoke;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.dashboard-content h1 {
    font-size: 48px;
    margin: 20px;
    margin-top: 10px;
}
.dashboard-content h4 {
    font-size: 18px;
    color: #e22b69;
    margin-bottom: 5px;
    font-weight: lighter;
}
.dashboard-content p {
    color: whitesmoke;
    padding-left: 150px;
    padding-right: 150px;
}
.footer {
    width: 100%;
    min-height: 100px;
    height: 100px;
    padding: 20px 80px;
    margin: 0;
    background:  linear-gradient(to right, #e22b69, #f2ec64);
    text-align: center;
    margin-bottom: 0px;
}
.footer p {
    color: whitesmoke;
    margin: 20px auto;
}
/* MENU BUTTON */
.menu-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    cursor: pointer;
    display:none;
}
.social-links img{
    height: 20px;
	margin:  20px;
	cursor:  pointer;
}
.social-links{
	text-align: center;
}
/* ANIMATIONS */
.drops img {
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease;
    box-shadow: 0px 2px 5px grey;
}
.drops img:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    box-shadow: 0px 2.1px 5.1px grey;
}
li:hover {
    color: #e22b69;
    cursor: pointer;
}

/* Styling for mobile */
@media only screen and (max-width:850px){
    .menu-btn {
        display: block;
    }
    .navbar {
        padding: 0;
    }
    .logo {
        position: absolute;
        top: 30px;
        left: 30px;
    }
    .nav-links {
        flex-direction: column;
        width: 100%;
        height: 100vh;
        justify-content: center;
        background: #000;
        margin-top: -900px;
        transition: all 0.5s ease;
    }
    .mobile-menu {
        margin-top: 0px;
    }
    .nav-links li {
        margin: 30px auto;
    }
    /* ROADMAP */
    .drops .row {
        flex-direction: column;
    }
    .drops .row .col {
        margin: 20px auto;
    }
    .wallet .row {
        flex-direction: column;
    }
    .wallet .row .col {
        margin: 20px auto;
    }
    .roadmap {
        max-height: 300px;
    }
    .roadmap-content {
        padding-left: 50px;
        padding-right: 50px;
    }
    .dashboard-content p {
        color: whitesmoke;
        padding-left: 50px;
        padding-right: 50px;
    }
    .footer {
        padding: 10px;
    }

}

